import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import './assets/styles.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { createI18n } from 'vue-i18n'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
library.add(fas as any)

// GSAP Initialization
gsap.registerPlugin(ScrollTrigger)

// i18n
const i18n = createI18n({
  // something vue-i18n options here ...
  locale: navigator.language || (navigator as any).userLanguage, // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: { en: require('@/assets/i18n/en.json') }
})

createApp(App)
  .use(router)
  .use(i18n)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mixin({
    created: function () {
      this.gsap = gsap
    }
  })
  .mount('#app')
