import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomePage from '../views/HomePage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/faq',
    name: 'FAQPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "faq" */ '../views/FAQPage.vue')
  },
  {
    path: '/how-did-you-meet-us',
    name: 'Survey',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "survey" */ '../views/Survey.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms and Conditions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "terms-and-conditions" */ '../views/TermsAndConditions.vue')
  },
  {
    path: '/delete-account',
    name: 'Delete Account',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "delete-account" */ '../views/DeleteAccount.vue')
  },
  {
    path: '/download',
    beforeEnter () {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).location = 'https://itunes.apple.com/app/inksquad-artist/id1579690331';
      (location as any).href = 'https://itunes.apple.com/app/inksquad-artist/id1579690331'
    }
  } as any
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})

export default router
