import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70be36c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "title" }
const _hoisted_2 = { class: "text-center uppercase text-gray-400 mb-4 text-sm lg:text-base" }
const _hoisted_3 = { class: "text-center mb-8 text-2xl md:text-5xl lg:text-7xl" }
const _hoisted_4 = { class: "text-center mb-4 text-2xl md:text-3xl lg:text-5xl" }
const _hoisted_5 = { class: "text-center leading-normal text-gray-200 text-base md:text-base lg:text-base" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainButton = _resolveComponent("MainButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, [
      _renderSlot(_ctx.$slots, "grey", {}, undefined, true)
    ]),
    _createElementVNode("h1", _hoisted_3, [
      _renderSlot(_ctx.$slots, "bigger-main", {}, undefined, true)
    ]),
    _createElementVNode("h1", _hoisted_4, [
      _renderSlot(_ctx.$slots, "main", {}, undefined, true)
    ]),
    _createElementVNode("p", _hoisted_5, [
      _renderSlot(_ctx.$slots, "text", {}, undefined, true)
    ]),
    _createVNode(_component_MainButton, { class: "text-center mt-8" }, {
      "button-text": _withCtx(() => [
        _renderSlot(_ctx.$slots, "button", {}, undefined, true)
      ]),
      _: 3
    })
  ]))
}