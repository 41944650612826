import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-335d2ecc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "contents",
  id: "columnitem"
}
const _hoisted_2 = { class: "element relative z-0 flex flex-wrap justify-top mx-2 pb-0 content-start" }
const _hoisted_3 = { class: "mock" }
const _hoisted_4 = { class: "z-10 mocks" }
const _hoisted_5 = { class: "text-description pt-8" }
const _hoisted_6 = { class: "text-white pb-4 text-left text-2xl md:text-3xl lg:text-5xl" }
const _hoisted_7 = { class: "text-gray-200 text-left text-base md:text-base lg:text-base" }
const _hoisted_8 = { class: "video-action flex items-center z-50 py-1 cursor-pointer text-white pt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "image", {}, undefined, true)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h2", _hoisted_6, [
          _renderSlot(_ctx.$slots, "title", {}, undefined, true)
        ]),
        _createElementVNode("p", _hoisted_7, [
          _renderSlot(_ctx.$slots, "text", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_font_awesome_icon, {
            class: "h-4 w-4",
            icon: ['fas', 'play-circle']
          }),
          _createElementVNode("h4", {
            class: "pl-2 hover:text-white",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('video')))
          }, _toDisplayString(_ctx.$t('components.general.watch-video')), 1)
        ])
      ])
    ])
  ]))
}