import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b32c38c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "section-2",
  ref: "Inkboards2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleTemplate = _resolveComponent("TitleTemplate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TitleTemplate, null, {
      main: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('components.homepage.inkboards2.title')), 1)
      ]),
      text: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('components.homepage.inkboards2.text-line-1')) + " " + _toDisplayString(_ctx.$t('components.homepage.inkboards2.text-line-2')), 1)
      ]),
      _: 1
    })
  ], 512))
}